import React from 'react';

/**
 * DiceHistory component that displays the results of the last two dice throws when the player just played.
 * @component
 * @param {DiceHistoryProps} props - The props of the component.
 * @param {DiceResult[]} props.lastDiceResults - The results of the last three dice throws.
 * @returns {React.FC} The DiceHistory component.
 */
const DiceHistory: React.FC<DiceHistoryProps> = ({ lastDiceResults }) => {
  const recentDiceColor = 'text-yellow-nine bg-gradient-orange-purple border-yellow-ten'
  const oldDiceColor = 'text-orange-four bg-gradient-dark-orange-purple border-orange-three'

  const getDiceHistory = (diceResult: DiceResult, index: number): React.ReactElement => {
    if (diceResult.value !== 0) {
      switch (index) {
        case 0:
          return (
            <div
              key={`dice-history-rl`}
              data-testid={`dice-history${index}`}
              className={`
              text-xl text-center h-10 flex justify-center flex-col font-alata
              absolute pl-1.5 w-16 border-y-2 border-l-2 rounded-l
              drop-shadow-yellow bottom-1/3 z-50 right-0
              ${recentDiceColor}
              `}
            >
              {diceResult.value}
            </div>
          );
        case 1:
          return (
            <div
              key={`dice-history-bt`}
              data-testid={`dice-history${index}`}
              className={`
              text-xl text-center h-10 flex justify-center flex-col font-alata
              absolute pl-1.5 w-16 border-y-2 border-l-2 rounded-l
              drop-shadow-yellow bottom-1/3 z-50 right-0
              -translate-y-6/5
              ${oldDiceColor}
              `}
            >
              {diceResult.value}
            </div>
          );
        default:
        return (<></>);
      }

    } else {
      return (<></>)
    }
  }

  return (
    <>
      {(lastDiceResults[0].value !== 0) && lastDiceResults.map(getDiceHistory)}
    </>
  );
}

export default DiceHistory;