import { useEffect } from 'react';

import { useFetch } from 'src/hooks/useFetchHooks';
import { useContextState } from 'src/context/Context';
import configuration, { endpoints } from 'src/config';

const useGameHooks = (gameIdFromUrl: string): void => {
  const { gameId, setGameId, setGameSession, gameSession } = useContextState();

  const urlGameSession = `${configuration.requestPrefix()}${endpoints.gameSession}${gameId}`;

  useEffect(() => {
    if (gameId === '' && gameIdFromUrl !== '') {
      setGameId(gameIdFromUrl);
    }
  }, [gameId, gameIdFromUrl, setGameId]);

  const { data: fetchedGameSession } = useFetch<GameSession>(urlGameSession);
  if (gameSession._id === '') {
    setGameSession(fetchedGameSession || {
      _id: '',
      _rev: '',
      racers: [],
      mapId: 0,
      gameState: '',
      timestamp: 0,
      diceResults: [],
      lastDiceResults: [
        { value: 0, isDifferentFromPrevious: true },
        { value: 0, isDifferentFromPrevious: true },
        { value: 0, isDifferentFromPrevious: true },
      ],
    });
  }
};

export default useGameHooks;
