import Navigation from './Navigation';
import { useContextState } from 'src/context/Context';
import { useParams } from 'react-router-dom';
import axios from '../../../axiosInstance';
import { backgrounds, endpoints } from '../../../config';
import useGameHooks from 'src/hooks/gameHooks';

const WaitingHub:React.FC = () => {
  const { gameIdFromUrl } = useParams();
  const { gameId, gameSession, setBackground } = useContextState();
  setBackground(backgrounds.readyToRace);

  useGameHooks(gameIdFromUrl || gameId);

  const currentPlayerId = JSON.parse(localStorage.getItem('user') ?? '{}')?.id;
  const isHost = gameSession.racers.find(
    racer => racer.racerId === currentPlayerId
  )?.host;


  const handleClick = (): void => {
    axios.post(`${endpoints.startGame}${gameIdFromUrl ?? gameId}`);
  };

  return (
    <div
      data-testid='waitingHub'
      className='w-full h-full flex flex-col justify-end items-center gap-1 pb-2'>
      {isHost &&
        <Navigation
          handleClick={handleClick}
          buttonText='GO!'
          loading={false}
        />
      }
    </div>
  );
};
export default WaitingHub;
