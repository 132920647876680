import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from 'src/views/connexion/Login';
import Password from 'src/views/connexion/Password';
import PasswordRedirection from 'src/views/connexion/PasswordRedirection';
import SignUp from 'src/views/connexion/SignUp';
import Menu from 'src/views/menu/Menu';
import Lobby from 'src/views/lobby/Lobby';
import PlayChoice from 'src/views/play/PlayChoice';
import PreparationHub from 'src/views/preparation-hub/PreparationHub';
import MapSelection from 'src/views/preparation-hub/preparation/MapSelection';
import CarSelection from 'src/views/preparation-hub/preparation/CarSelection';
import WaitingHub from 'src/views/preparation-hub/preparation/WaitingHub';
import Game from 'src/views/game/Game';
import Profile from 'src/views/profile/Profile';
import ProfileCardUpdate from '../profile/ProfileCardUpdate';
import Pilotes from 'src/views/pilotes/Pilotes';
import Tunning from 'src/views/tunning/Tunning';
import RulesGame from 'src/views/game-rules/RulesGame';
import ErrorPage from 'src/views/error/ErrorPage';
import Notifications from 'src/views/notification/Notification';
import ProtectedRoutes from '../protected-routes/ProtectedRoutes';

const CustomRouter:React.FC<CustomRouterProps> = ({ currentLocation }) => {
  return (
      <Routes>
        {/* ----------ROUTE CONNEXION ---------------- */}
        <Route path='/' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/password' element={<Password />} />
        <Route path='password_redirection' element={<PasswordRedirection />} />

        <Route element={<ProtectedRoutes currentLocation={currentLocation} />}>

          {/* ----------ROUTE MENU --------------------- */}
          <Route path='/menu' element={<Menu />} />

          {/* ----------ROUTE GAME --------------------- */}
          <Route path='/lobby' element={<Lobby />}>
            <Route path='' element={<PlayChoice />} />
            <Route path=':gameIdFromUrl' element={<PreparationHub />}>
              <Route path='map' element={<MapSelection />} />
              <Route path='car' element={<CarSelection />} />
              <Route path='waiting-hub' element={<WaitingHub />} />
            </Route>
          </Route>
          <Route path='/the_race/:gameIdFromUrl' element={<Game />} />

          {/* ----------ROUTE PROFIL -------------------- */}
          <Route path='/profil' element={<Profile />} />
          <Route path='/edit_profil' element={<ProfileCardUpdate />} />
          <Route path='/pilotes' element={<Pilotes />} />

          {/* ----------ROUTE AUTRES -------------------- */}
          <Route path='/tuning' element={<Tunning />} />
          <Route path='/rules' element={<RulesGame />} />
          <Route path='/notifications' element={<Notifications />} />
        </Route>

        {/* ----------ROUTE ERROR --------------------- */}
        <Route path='*' element={<ErrorPage />} />
      </Routes>
  );
};

export default CustomRouter;
