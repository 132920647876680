import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import configuration, { endpoints, backgrounds } from '../config';
import { useFetch } from '../hooks/useFetchHooks';
import { TypeAction } from 'src/enum/gameSession';

const stateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<StateProviderProps> = ({ children }) => {
  const [gameId, setGameId] = useState<string>('');
  const [gameSession, setGameSession] = useState<GameSession>({
    _id: '',
    _rev: '',
    racers: [],
    mapId: 0,
    gameState: '',
    timestamp: 0,
    diceResults: [],
    lastDiceResults: [
      { value: 0, isDifferentFromPrevious: true },
      { value: 0, isDifferentFromPrevious: true },
      { value: 0, isDifferentFromPrevious: true },
    ],
  });
  const [allCars, setAllCars] = useState<Car[] | undefined>(undefined);
  const [allMaps, setAllMaps] = useState<CircuitMap[] | undefined>(undefined);
  const [allActionButtons, setAllActionButtons] = useState<ActionButton[] | undefined>(undefined);
  const [playerId, setPlayerId] = useState<number>();
  const [selectRacerEvent, setSelectRacerEvent] = useState<SelectRacerEvent | undefined>(undefined);
  const [winnerId, setWinnerId] = useState<number>();
  const [cardIsHeld, setCardIsHeld] = useState<boolean>(false);
  const [justPlayed, setJustPlayed] = useState<boolean>(false);
  const [startCardTimers, setStartCardTimers] = useState<number>(0);
  const [singleDiceThrow, setSingleDiceThrow] = useState<boolean>(false);
  const [newDicesThrow, setNewDicesThrow] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<TypeAction>(TypeAction.MAIN);
  const [toggleDiceAnimation, setToggleDiceAnimation] = useState<number[]>([]);
  const [lastDiceResults, setLastDiceResults] = useState<number[]>([0, 0, 0]);
  const [background, setBackground] = useState<string>(backgrounds.garagePortal);


  const urlFetchCar = `${configuration.requestPrefix()}${endpoints.fetchCar}/${playerId}/cars`;
  const urlFetchMap = `${configuration.requestPrefix()}${endpoints.fetchMap}/${playerId}/maps`;
  const urlFetchActionButton = `${configuration.requestPrefix()}${endpoints.fetchActionButton}`;

  const { data: dataCar } = useFetch<PrefixCar>(playerId === undefined ? undefined : urlFetchCar);
  const { data: dataMap } = useFetch<PrefixMap>(playerId === undefined ? undefined : urlFetchMap);
  const { data: dataActionButton } = useFetch<ActionButton[]>(playerId === undefined ? undefined : urlFetchActionButton);

  useEffect(() => {
    if (
      dataCar &&
      Array.isArray(dataCar._embedded.cars) &&
      dataCar._embedded.cars.length > 0
    ) {
      setAllCars(dataCar._embedded.cars);
    }
    if (
      dataMap &&
      Array.isArray(dataMap._embedded.maps) &&
      dataMap._embedded.maps.length > 0
    ) {
      setAllMaps(dataMap._embedded.maps);
    }
    if (
      dataActionButton &&
      Array.isArray(dataActionButton) &&
      dataActionButton.length > 0
    ) {
      setAllActionButtons(dataActionButton);
    }
  }, [dataCar, allCars, setAllCars, dataMap, allMaps, setAllMaps, playerId, dataActionButton, allActionButtons, setAllActionButtons]);

  const contextValue = useMemo(() => ({
    gameId,
    setGameId,
    gameSession,
    setGameSession,
    allCars,
    setAllCars,
    allMaps,
    setAllMaps,
    allActionButtons,
    setAllActionButtons,
    playerId,
    setPlayerId,
    selectRacerEvent,
    setSelectRacerEvent,
    winnerId,
    setWinnerId,
    cardIsHeld,
    setCardIsHeld,
    justPlayed,
    setJustPlayed,
    startCardTimers,
    setStartCardTimers,
    singleDiceThrow,
    setSingleDiceThrow,
    newDicesThrow,
    setNewDicesThrow,
    currentAction,
    setCurrentAction,
    toggleDiceAnimation,
    setToggleDiceAnimation,
    lastDiceResults,
    setLastDiceResults,
    background,
    setBackground,
  }), [
    gameId,
    setGameId,
    gameSession,
    setGameSession,
    allCars,
    setAllCars,
    allMaps,
    setAllMaps,
    allActionButtons,
    setAllActionButtons,
    playerId,
    setPlayerId,
    selectRacerEvent,
    setSelectRacerEvent,
    winnerId,
    setWinnerId,
    cardIsHeld,
    setCardIsHeld,
    justPlayed,
    setJustPlayed,
    startCardTimers,
    setStartCardTimers,
    singleDiceThrow,
    setSingleDiceThrow,
    newDicesThrow,
    setNewDicesThrow,
    currentAction,
    setCurrentAction,
    toggleDiceAnimation,
    setToggleDiceAnimation,
    lastDiceResults,
    setLastDiceResults,
    background,
    setBackground
  ]);

  return (
    <stateContext.Provider value={contextValue}>
      {children}
    </stateContext.Provider>
  );
};

export const useContextState = (): StateContext => {
  const context = useContext(stateContext);
  if (context === undefined) {
    throw new Error('useContextState must be used within a StateProvider');
  }
  return context;
};
