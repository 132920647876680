import { useLocation } from 'react-router-dom';
import './index.css';
import CustomRouter from './components/custom-router/CustomRouter';
import { useJsonPayloadHandler } from './hooks/wsEventHandler';
import { Toaster, toast } from 'sonner';
import { useEffect } from 'react';
import { backgrounds } from './config';
import { useContextState } from './context/Context';

function App() {
  const { notif } = useJsonPayloadHandler();
  const currentLocation = useLocation().pathname;
  const { background, setBackground } = useContextState();

  if (currentLocation.includes('/lobby') || currentLocation.includes('/menu')) {
    setBackground(backgrounds.garagePortal);
  }

  const darkOverlay = 'bg-black/20 ';
  const blurryOverlay = 'backdrop-blur-sm';
  const darkenBgOne = 'bg-dark-one bg-blend-darken';
  const darkenBgTwo = 'bg-dark-two bg-blend-darken';

  let overlay = '';
  if (background !== backgrounds.garagePortalRacer) {
    overlay += darkOverlay;
    if (background !== backgrounds.readyToRace) {
      overlay += blurryOverlay;
    }
  }

  let darkenBg = darkenBgOne;
  if (currentLocation.includes('/the_race')) {
    darkenBg = darkenBgTwo;
  }
  if (background === backgrounds.readyToRace || background === backgrounds.garagePortalRacer) {
    darkenBg = '';
  }

  useEffect(() => {
    if (notif) {
      toast.success(notif);
    }
  }, [notif]);

  return (
    <div
      className={`
        ${darkenBg}
        w-screen h-screen
        bg-cover bg-center
        overflow-x-hidden overflow-y-hidden
        flex justify-center items-center
      `}
      style={{backgroundImage: `url(${background})`}}
    >
      <div
        className={`
          ${overlay}
          h-full sm:h-[98%] w-full sm:w-[360px]
          rounded-none sm:rounded-xl
        `}
      >
        {notif && <Toaster visibleToasts={1} richColors position='top-center' />}
        <CustomRouter currentLocation={currentLocation} />
      </div>
    </div>
  );
}

export default App;
