import { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { BsEyeSlashFill, BsEyeFill } from 'react-icons/bs';
import axios from '../../axiosInstance';
import { HttpStatusCode } from 'axios';
import Logo from '../../components/header/Logo';
import configuration, { backgrounds, menuLoadingDelay } from '../../config';
import { useContextState } from 'src/context/Context';

axios.defaults.withCredentials = true;

const Login:React.FC = () => {
  const { setBackground } = useContextState();

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [errorText, setErrorText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // url for database
  const url = configuration.requestPrefix() + '/users/login';

  //Function for error activation/ desactivation errorMessage
  const errorMessage = (): void => {
    setErrorText(true);
  };

  // Function for change data in form
  const handleChangeForm = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setErrorText(false);
  };

  // Function for submit form and post data in database and redirection to loader page
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    localStorage.clear();
    axios
      .post(
        url,
        {
          username: formData.username,
          password: formData.password,
        },
        { headers: { 'content-type': 'application/x-www-form-urlencoded' }, withCredentials: true }
      )
      .then(
        resLogin => {
          if (resLogin.status === HttpStatusCode.Ok) {
            localStorage.setItem('token', resLogin.data);

            const urlPlayerInfos = configuration.requestPrefix() + '/users/me';
            axios.get(urlPlayerInfos).then((resGetLoggedUser): void => {
              if (resGetLoggedUser.status === HttpStatusCode.Ok) {
                localStorage.setItem('user', JSON.stringify(resGetLoggedUser.data));
              }
            });
          }
        },
        () => {
          errorMessage();
        }
      )
      .catch(() => {
        // Implement log service
      })
      .then(() => {
        setBackground(backgrounds.garagePortalRacer);
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          navigate('/menu');
        }, menuLoadingDelay);
      });
  };

  return (
    !isLoading ? (
      <div className="flex flex-col mt-24 w-full h-full">
        <Logo />
        <div className="bg-gray-500 w-[90%] rounded-lg mx-auto py-4">
          <div className="flex flex-col justify-center items-center">
            <form
              className="rounded px-8 mb-4 min-w-full"
              onSubmit={handleSubmit}
              data-testid="connexion-submit"
            >
              {errorText && (
                <p
                  className="text-[10px] text-red-500 italic"
                  data-testid="error-message-text"
                >
                  Combinaison mot de passe/identifiant incorrect,
                  <br /> veuillez réessayer
                </p>
              )}
              <div className="mb-6">
                <h2 className="profil-title">PSEUDO</h2>
                <input
                  data-testid="pseudo-input"
                  className="inputLogin"
                  type="text"
                  name="username"
                  placeholder="Saisir entre 3 et 20 caractères"
                  value={formData.username}
                  onChange={handleChangeForm}
                />
              </div>
              <div className="mb-6">
                <h2 className="profil-title">MOT DE PASSE</h2>
                <div className="flex">
                  <input
                    data-testid="password-input"
                    className="inputLogin"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    placeholder="********"
                    value={formData.password}
                    onChange={handleChangeForm}
                  />
                  <button
                    type="button"
                    data-testid="show-password-button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="text-gray-300 text-2xl px-2"
                  >
                    {showPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-blue-400 hover:text-gray-400">
                  <NavLink to="/password"> Mot de passe oublié ?</NavLink>
                </div>
                <button
                  type="submit"
                  value="formData"
                  data-testid="submit-button"
                  className="btn-connexion"
                >
                  Se connecter
                </button>
                <div>
                  <div className="text-white hover:text-gray-400">
                    <NavLink to="/signup"> Besoin d'un compte ?<span className='text-blue-400'> S'inscrire</span> </NavLink>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    ) : (
      <></>
    )
  );
};

export default Login;
